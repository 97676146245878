<!--
 * ======================================
 * 说明： 提示框
 * 作者： Silence
 * 文件： model.vue
 * 日期： 2023/7/23 0:03
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div v-if="visible" class="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center" style="background-color: rgba(0,0,0,0.2)">
      <div class="w-2/3 bg-white rounded overflow-hidden">
        <div class="text-center text-gray-700 px-2 py-4">{{ text }}</div>
        <div class="border-t-2 flex justify-between items-center">
          <div class="p-2 border-r-2 flex-1 text-center text-white " style="background-color: #4283F7" @click="handleOk">确 定</div>
          <div class="p-2 flex-1 text-center" @click="visible = false">取 消</div>
        </div>
      </div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value: {
        type: Boolean,
        default: false
      },
      text:{
        type: String,
        default: '确定执行该操作吗？'
      }
    },
    data() {
      return {
      }
    },
    computed: {
      visible:{
        get(){
          return this.value
        },
        set(val){
          this.$emit('input',val)
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      // 确定
      handleOk(){
        this.$emit('confirm')
        this.visible = false;
      }
    },
  }
</script>
<style lang="scss" scoped></style>