<!--
 * ======================================
 * 说明： 修改密码
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/7/22 23:33
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="modify-password-layout" v-loading="loading">
    <mobileHeader />
    <div class="modify-password-main w-11/12 mx-auto shadow-md mt-10">
      <el-form :model="formData" :rules="rules" ref="formData">
        <el-form-item label="旧密码" prop="password">
          <el-input show-password v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input show-password v-model="formData.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rNewPassword">
          <el-input show-password v-model="formData.rNewPassword"></el-input>
        </el-form-item>
        <el-button type="primary" class="w-full" @click="handleUpdatePassword"
          >修改密码</el-button
        >
      </el-form>
    </div>
    <model v-model="visible" text="确定要修改密码吗?" @confirm="handleConfirm"></model>
  </div>
</template>
<script>
import mobileHeader from "../components/header.vue";
import model from "../components/model.vue";
export default {
  name: "",
  components: { mobileHeader ,model},
  props: {},
  data() {
    return {
      loading:false,
      visible:false,
      formData: {},
      rules: {
        password: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入密码"));
              } else if (value.length < 6) {
                callback(new Error("密码长度不能小于6位"));
              } else {
                if (this.formData.rNewPassword !== "") {
                  this.$refs.formData.validateField("rNewPassword");
                }
                callback();
              }
            },
          },
        ],
        rNewPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.formData.newPassword) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 修改密码
    handleUpdatePassword() {
      this.$refs["formData"].validate((vaild) => {
        if (vaild) {
          this.visible = true;
        }
      });
    },
    /**
     *  @actions: 确定修改密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/23 0:21
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm(){
      const { password, newPassword } = this.formData;
      this.loading = true;
      this.$cloud
        .post("/login/password", {
          password,
          newPassword,
        })
        .then(() => {
          this.loading = false;
          this.formData = {};
          this.$message.success("修改成功！");
          this.$cloud.go('/mobile/index/index');
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.modify-password-layout {
  min-width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: #EEF4F9;
}
.modify-password-main {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
}
</style>
